import React from 'react';
import * as styles from '../css/stylesheet';
import Box from '@mui/material/Box';
import Plus from './plus';
import Minus from './minus';
import TextField from '@mui/material/TextField';

interface Props {
  count: number;
  functions: any;
}

function NumberSelection({ count, functions }: Props) {
  return (
    <>
      <Box sx={{ display: 'flex', bgcolor: 'background.paper', paddingTop: '12px' }}>
        <div style={styles.FlexGrow}></div>
        <Minus onClick={() => functions.plus()} />
        <TextField
          value={count}
          InputProps={{
            inputProps: {
              style: { width: '30px', padding: '0px', textAlign: 'center', marginTop: '5px' },
            },
            readOnly: true,
          }}
        />
        <Plus onClick={() => functions.minus()} />
      </Box>
    </>
  );
}

export default NumberSelection;
