import { atom, selector } from 'recoil';
import { purchaseTicketModel } from '../../models/purchaseTicket';

export const selectedTicketState = atom({
  key: 'selectedTicket',
  default: '',
});

export const purchaseTicketsState = atom<purchaseTicketModel[]>({
  key: 'PurchaseTickets',
  default: Promise.resolve([]),
});

export const getPurchaseTicketState = selector({
  key: 'getPurchaseTicket',
  get: ({ get }) => {
    return get(purchaseTicketsState);
  },
});

export const filteredTicketState = selector({
  key: 'filteredTicket',
  get: ({ get }) => {
    const list = get(purchaseTicketsState);
    let filterId = get(selectedTicketState);

    return list.filter((item) => item.id === filterId);
  },
});
