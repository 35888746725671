import React from 'react';
import * as styles from '../css/stylesheet';
import ExplainArea from './explain_area';
import Box from '@mui/material/Box';

interface Props {
  ticketName: string;
  price?: number;
  count?: number;
  totalPrice: number;
  usePeriod: number;
}

function TicketConfirmation({ ...props }: Props) {
  let textSummary = '';
  if (props.count !== 0) {
    textSummary += `${props.price}円/枚×${props.count}`;
  }
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <ExplainArea title="ご購入チケット名" text={props.ticketName} />{' '}
        </Box>
      </Box>

      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <ExplainArea title="購入枚数" text={textSummary} />
        </Box>
      </Box>

      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <ExplainArea title="合計金額" text={`${props.totalPrice.toLocaleString()}円`} />
        </Box>
      </Box>

      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <ExplainArea title="使用期限" text={`${props.usePeriod}日間（購入日含む）`} />
        </Box>
      </Box>
    </>
  );
}

export default TicketConfirmation;
