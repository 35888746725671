import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { pathState } from '../../states/rootStates/path';

interface Props {
  title: string;
  url: string;
  back: string;
}

function UrlLink({ title, url, back }: Props) {
  let navigate = useNavigate();
  const setBack = useSetRecoilState(pathState);
  useEffect(() => {
    setBack(back);
  }, [back, setBack]);
  return (
    <>
      <div
        style={{
          textAlign: 'center',
          marginTop: '1em',
          textDecoration: 'underline',
          color: '#1976d2',
        }}
        onClick={() => navigate(url)}
      >
        {title}
      </div>
    </>
  );
}

export default UrlLink;
