import React from 'react';

interface Props {
  onClick?: () => void;
}

function Plus({ ...props }: Props) {
  return (
    <>
      <img src="/images/svg/plus.svg" alt="plus" {...props} />
    </>
  );
}

export default Plus;
