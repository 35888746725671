import React from 'react';
import * as styles from '../css/stylesheet';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

interface Props {
  size?: 'small' | 'medium' | 'large';
  label: string;
  url: string;
  borderRadius?: number;
  width?: string;
  marginTop?: string;
  onClick?: () => void;
}

function ButtonTicket({
  size = 'large',
  label,
  borderRadius = 10,
  width = '100%',
  marginTop = '1em',
  ...props
}: Props) {
  return (
    <>
      <ThemeProvider theme={styles.themeMain}>
        <Button
          variant="contained"
          size={size}
          style={{
            borderRadius: borderRadius,
            width: width,
            marginTop: marginTop,
            fontWeight: 'bold',
            fontSize: '20px',
          }}
          {...props}
        >
          <Grid container>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Avatar variant="square" src={props.url} />
            </Grid>
            <Grid
              item
              xs={11}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'left',
                paddingLeft: '1.5em',
                paddingRight: '1.5em',
                whiteSpace: 'pre-line',
              }}
            >
              {label}
            </Grid>
          </Grid>
        </Button>
      </ThemeProvider>
    </>
  );
}

export default ButtonTicket;
