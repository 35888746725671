import * as React from 'react';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TicketOwnSammary from '../../uiParts/ticket_own_sammary';
import {
  filteredOwnTicketBeforeUseState,
  filteredOwnTicketUseState,
  filteredOwnTicketAfterUseState,
  selectedOwnTicketState,
} from '../../../states/rootStates/ownTickets';
import { useRecoilValue, useSetRecoilState } from 'recoil';

function OwnershipTicketList() {
  const [value, setValue] = React.useState('1');
  const setOwnSelectedTicket = useSetRecoilState(selectedOwnTicketState);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  function setOwnTicket(id: string) {
    setOwnSelectedTicket(() => id);
  }
  let navigate = useNavigate();
  function next1(value: string) {
    setOwnTicket(value);
    navigate('/ticket/ownership/detail');
  }

  const beforeUseList = useRecoilValue(filteredOwnTicketBeforeUseState);
  const useList = useRecoilValue(filteredOwnTicketUseState);
  const afterUseList = useRecoilValue(filteredOwnTicketAfterUseState);

  return (
    <>
      <Header url="/" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              centered
              variant="fullWidth"
            >
              <Tab label="使用前" value="1" />
              <Tab label="使用中" value="2" />
              <Tab label="履歴" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            {beforeUseList.length === 0 && <h3>該当のチケットがありません</h3>}
            {beforeUseList.map((ownticket) => (
              <TicketOwnSammary
                key={ownticket.id}
                title={ownticket.ticket.title}
                count={ownticket.count}
                buyDate={ownticket.created}
                usePeriod={ownticket.available}
                onClick={() => next1(ownticket.id)}
              />
            ))}
          </TabPanel>
          <TabPanel value="2">
            {useList.length === 0 && <h3>該当のチケットがありません</h3>}
            {useList.map((ownticket) => (
              <TicketOwnSammary
                key={ownticket.id}
                title={ownticket.ticket.title}
                count={ownticket.count}
                buyDate={ownticket.created}
                usePeriod={ownticket.available}
                onClick={() => next1(ownticket.id)}
              />
            ))}
          </TabPanel>
          <TabPanel value="3">
            {afterUseList.length === 0 && <h3>該当のチケットがありません</h3>}
            {afterUseList.map((ownticket) => (
              <TicketOwnSammary
                key={ownticket.id}
                title={ownticket.ticket.title}
                count={ownticket.count}
                buyDate={ownticket.created}
                usePeriod={ownticket.available}
              />
            ))}
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
}

export default OwnershipTicketList;
