import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from '../css/stylesheet';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';

interface Props {
  url: string;
  title?: string;
  external?: boolean;
  displayHome?: boolean;
}

const redirectExternal = (url: string) => {
  window.location.href = url;
};

function Header({ url, title = '', external = false, displayHome = true }: Props) {
  let navigate = useNavigate();
  return (
    <>
      <div style={{ marginBottom: '64px' }}>
        <AppBar position="fixed" style={styles.HeaderColor}>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Button
                onClick={() => (external ? redirectExternal(url) : navigate(url))}
                sx={{ color: '#3A1D0B', fontSize: '16px', fontWeight: 'bold' }}
              >
                ＜戻る
              </Button>
              <Box sx={{ flexGrow: 1 }} />
              <IconButton
                onClick={() => navigate('/')}
                sx={{ display: displayHome ? 'block' : 'none' }}
              >
                <img src="/images/svg/home.svg" alt="home" />
              </IconButton>
            </Toolbar>
          </Container>
        </AppBar>
      </div>
    </>
  );
}

export default Header;
