import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import TicketNumberSelection from '../../uiParts/ticket_number_selection';
import Button from '../../uiParts/button';
import UrlLink from '../../uiParts/url_link';
import { countState } from '../../../states/rootStates/count';
import { filteredTicketState } from '../../../states/rootStates/purchaseTickets';
import { useRecoilState, useRecoilValue } from 'recoil';

function PurchaseTicketNumberSelection() {
  let navigate = useNavigate();
  function next1() {
    navigate('/ticket/purchase/confirmation');
  }
  const [count, setCount] = useRecoilState(countState);
  function Plus() {
    if (count === 10) {
      return;
    }
    setCount((c) => c + 1);
  }
  function Minus() {
    if (count === 0) {
      return;
    }
    setCount((c) => c - 1);
  }

  const functions = { Plus, Minus };

  const ticketsList = useRecoilValue(filteredTicketState);

  let disabledNext = true;
  if (count !== 0) {
    disabledNext = false;
  }

  return (
    <>
      <Header url="/ticket/purchase/detail" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>購入枚数の選択</h2>
        {ticketsList.map((ticket) => (
          <div key={ticket.id}>
            <TicketNumberSelection
              title={ticket.title}
              price={ticket.price}
              count={count}
              functions={functions}
            />
          </div>
        ))}
        <Button label="次へ" onClick={next1} disabled={disabledNext} />
        <UrlLink
          title="特定商取引に基づく表示"
          url="/tokushou"
          back="/ticket/purchase/number_selection"
        />
      </Box>
    </>
  );
}

export default PurchaseTicketNumberSelection;
