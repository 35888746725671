import * as React from 'react';
import * as styles from '../css/stylesheet';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from './button';
import Typography from '@mui/material/Typography';

interface Props {
  open: boolean;
  title: string;
  text: string;
  labelOK: string;
  labelCancel: string;
  handleClose?: () => void;
  handleNext: () => void;
  url?: string;
  ticketName?: string;
  count?: number;
  scroll?: boolean;
}

function VerificationModal({
  open,
  title,
  text,
  labelOK,
  labelCancel,
  handleClose = undefined,
  handleNext,
  url = '',
  ticketName = '',
  count = 0,
  scroll = false,
}: Props) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={styles.Modal}>
            {ticketName && <h2 id="transition-modal-ticketName">{ticketName}</h2>}
            {url && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  margin: '1em',
                }}
              >
                <img src={url} alt="ticket" style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            )}
            {ticketName && (
              <Typography
                id="transition-modal-count"
                component="h2"
                style={{ textAlign: 'center', fontSize: '3.0em' }}
              >
                {`${count}名分`}
              </Typography>
            )}
            <h4 id="transition-modal-title" style={{ textAlign: 'center' }}>
              {scroll ? (
                <div style={styles.Scroll}>
                  <span style={styles.ScrollSpan}>{title}</span>
                </div>
              ) : (
                title
              )}
            </h4>
            <h4 id="transition-modal-description" style={{ textAlign: 'center' }}>
              {text}
            </h4>
            <Box
              sx={{
                display: 'flex',
                bgcolor: 'background.paper',
              }}
            >
              {handleClose && (
                <Button label={labelCancel} borderRadius={0} onClick={() => handleClose()} />
              )}
              <Button label={labelOK} borderRadius={0} onClick={() => handleNext()} />
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default VerificationModal;
