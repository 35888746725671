import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Box from '@mui/material/Box';
import Button from '../../uiParts/button';
import Modal from '../../uiParts/modal';
import { purchaseTicketsState } from '../../../states/rootStates/purchaseTickets';
import { ownTicketsState } from '../../../states/rootStates/ownTickets';
import { jwtTokenState } from '../../../states/rootStates/jwtToken';
import { ownCardsState } from '../../../states/rootStates/ownCards';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { getPurchaseTickets, getOwnTickets } from '../../../api/api';
import { getCard } from '../../../api/api';
import Header from '../../uiParts/header';

import AlertMessage from '../../uiParts/alert_message';
import { openAlertState, messageAlertState } from '../../../states/rootStates/alert';
import { backMenuState } from '../../../states/rootStates/backMenu';

import DialogCard from '../../uiParts/dialog_card';
import TutorialDialog from '../../uiParts/tutorial_dialog';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

function Menu() {
  let navigate = useNavigate();
  const setPurchaseTicketData = useSetRecoilState(purchaseTicketsState);
  const setOwnTicketData = useSetRecoilState(ownTicketsState);
  const [tokenState, setJwtToken] = useRecoilState(jwtTokenState);
  const [open, setOpen] = useRecoilState(openAlertState);
  const [message, setMessage] = useRecoilState(messageAlertState);
  const [cardState, setCardState] = useRecoilState(ownCardsState);
  const [userError, setUserError] = useState(false);
  const [openUserError, setOpenUserError] = useState(false);
  const handleCloseUserError = () => setOpenUserError(false);
  const [cardAlert, setCardAlert] = useState(false);
  const [backMenu, setBackMenu] = useRecoilState(backMenuState);
  const [isLoading, setIsLoading] = useState(true);
  const [isTutorial, setIsTutorial] = useState<boolean>(true);

  useEffect(() => {
    if (document.referrer !== null && document.referrer !== '') {
      setBackMenu(document.referrer);
      document.cookie = 'backurl=' + document.referrer;
    }

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const jwtToken = urlParams.get('jwt');
    if (jwtToken !== null) {
      setJwtToken(jwtToken);
    }
    if (jwtToken !== null || tokenState) {
      let user = jwtToken || tokenState;
      getCard(user)
        .then((res) => {
          if (res != null && res['data'] != null) {
            if (res['data']['id'] != null && res['data']['id'] !== '') {
              cardState || setCardState(true);
              setIsLoading(false);
            } else {
              cardState && setCardState(false);
              setIsLoading(false);
            }
          } else {
            setIsLoading(false);
            setUserError(true);
            setOpenUserError(true);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setUserError(true);
      setOpenUserError(true);
    }
  }, [setJwtToken, setCardState, cardState, tokenState, setBackMenu]);

  function next1() {
    if (cardState !== false) {
      getPurchaseTickets().then((res) => {
        if (res.length === 0 || res[0].error.length === 0) {
          setPurchaseTicketData(res);
          navigate('/ticket/purchase/list');
        } else {
          setOpen(true);
          setMessage(res[0].error);
        }
      });
    } else {
      cardAlert || setCardAlert(true);
    }
  }

  const closeAlert = () => {
    cardAlert && setCardAlert(false);
  };

  function next2() {
    getOwnTickets(tokenState).then((res) => {
      if (res.length === 0 || res[0].error.length === 0) {
        setOwnTicketData(res);
        navigate('/ticket/ownership/list');
      } else {
        setOpen(true);
        setMessage(res[0].error);
      }
    });
  }
  function next3() {
    navigate('/credit_card/view');
  }

  const [expanded, setExpanded] = useState(true);
  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      {!isLoading && (
        <>
          <Header url={backMenu} external={true} displayHome={false} />
          <div style={{ marginTop: '10em' }} />
          <AlertMessage open={open} message={message} handleClose={() => setOpen(false)} />
          <Box sx={styles.FlexP1BgcolorPage}>
            <Accordion sx={{ backgroundColor: 'pink' }} expanded={expanded}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ fontWeight: 'bold' }}
                onClick={toggleAccordion}
              >
                休止のお知らせ
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  本サービスは2024年10月7日より2025年3月31日まで休止致します。
                </Typography>
                <Typography>チケットの販売/利用機能は2024年10月6日までご利用頂けます。</Typography>
                <Typography>10月7日以降に未使用のチケットは自動的に払い戻し致します。</Typography>
              </AccordionDetails>
            </Accordion>
            <Button
              label="チケットを購入する"
              onClick={next1}
              disabled={userError}
              marginTop="20px"
            />
            <Button
              label="チケットを確認・使用する"
              onClick={next2}
              disabled={userError}
              marginTop="20px"
            />
            <Button
              label="クレジットカードを登録する"
              onClick={next3}
              disabled={userError}
              marginTop="20px"
            />
            <Modal
              open={openUserError}
              title="パラメータ不正"
              text={`パラメータが不足しておりユーザーが判別できません。`}
              labelOK="OK"
              labelCancel="キャンセル"
              handleNext={handleCloseUserError}
            />
            <Modal
              open={cardAlert}
              title="決済手段の登録について"
              text={`お支払い方法が未登録です。クレジットカードのご登録をお願いいたします。`}
              labelOK="OK"
              labelCancel="キャンセル"
              handleNext={closeAlert}
            />
          </Box>
          {!localStorage.getItem('KAGA-TICKET-TUTORIAL') && isTutorial && (
            <TutorialDialog
              isChecked={false}
              title={'デジタルでチケットを購入できるので、いつでも確認OK'}
              description={
                '１：ご乗車時の際にチケットを使うボタンを押すとご利用開始です。\n２：お降りの際は降車するボタンを押し、乗務員にお見せください。'
              }
              buttonText={'チケット購入へ'}
              card={<DialogCard image={'/images/png/ticket-tutorial.png'} />}
              handleClick={(isAgree) => {
                if (isAgree) {
                  localStorage.setItem('KAGA-TICKET-TUTORIAL', 'agree');
                }
                setIsTutorial(false);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export default Menu;
