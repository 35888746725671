import React from 'react';
import * as styles from '../css/stylesheet';
import Box from '@mui/material/Box';

interface Props {
  price: number;
}

function Price({ price }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <div style={{ padding: '8px' }}>{price.toLocaleString()}円</div>
        </Box>
      </Box>
    </>
  );
}

export default Price;
