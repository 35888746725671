import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Button from '../../uiParts/button';

function OwnershipTicketCancel() {
  let navigate = useNavigate();
  function next1() {
    navigate('/');
  }
  return (
    <>
      <div style={{ marginTop: '10em' }} />
      <Grid container spacing={2}>
        <Grid item xs={2} md={3}></Grid>
        <Grid item xs={8} md={6}>
          チケットが払い戻し申請されました。
          <br />
          再び購入する場合は、チケット一覧から再購入してください。
        </Grid>
        <Grid item xs={2} md={3}></Grid>
        <Grid item xs={2} md={3}></Grid>
        <Grid item xs={8} md={6}>
          <Button label="OK" onClick={next1} />
        </Grid>
        <Grid item xs={2} md={3}></Grid>
      </Grid>
    </>
  );
}

export default OwnershipTicketCancel;
