import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TicketDetailStyle, TicketDetailHeaderStyle } from '../css/stylesheet';
import TicketNumber from './ticket_number';
import Box from '@mui/material/Box';

interface Props {
  title: string;
  price: number;
  count: number;
  functions: any;
}

function TicketNumberSelection({ title, price, count, functions }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={TicketDetailStyle}>
            <CardContent style={TicketDetailHeaderStyle}>{title}</CardContent>

            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <TicketNumber price={price} count={count} functions={functions} />
              </Box>
            </Box>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <span style={{ verticalAlign: 'top' }}>支払い金額</span>
                <hr />
              </Box>
            </Box>

            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <span style={{ verticalAlign: 'top' }}>合計金額</span>
              </Box>
              <div>{(price * count).toLocaleString()}円（税込）</div>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default TicketNumberSelection;
