import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import TicketImage from '../../uiParts/ticket_image';
import TicketSammary from '../../uiParts/ticket_sammary';
import UrlLink from '../../uiParts/url_link';
import { filteredTicketState } from '../../../states/rootStates/purchaseTickets';
import { useRecoilValue } from 'recoil';

function PurchaseTicketSammary() {
  let navigate = useNavigate();
  function next1() {
    navigate('/ticket/purchase/detail');
  }

  const ticketsList = useRecoilValue(filteredTicketState);

  return (
    <>
      <Header url="/ticket/purchase/list" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>チケットの購入</h2>
        {ticketsList.map((ticket) => (
          <div key={ticket.id}>
            <TicketImage image_url={ticket.url} title={ticket.title} />
            <h3>チケットを選択してください</h3>
            <TicketSammary title={ticket.title} price={ticket.price} onClick={next1} />
          </div>
        ))}

        <UrlLink title="特定商取引に基づく表示" url="/tokushou" back="/ticket/purchase/sammary" />
      </Box>
    </>
  );
}

export default PurchaseTicketSammary;
