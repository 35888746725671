import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import Button from '../../uiParts/button';
import TicketConfirmation from '../../uiParts/ticket_comfirmation';
import UrlLink from '../../uiParts/url_link';
import { countState } from '../../../states/rootStates/count';
import {
  filteredTicketState,
  selectedTicketState,
} from '../../../states/rootStates/purchaseTickets';
import { jwtTokenState } from '../../../states/rootStates/jwtToken';
import { useRecoilValue, useRecoilState } from 'recoil';
import { buyTicket } from '../../../api/api';
import AlertMessage from '../../uiParts/alert_message';
import { openAlertState, messageAlertState } from '../../../states/rootStates/alert';

function PurchaseTicketConfirmation() {
  let navigate = useNavigate();
  const [open, setOpen] = useRecoilState(openAlertState);
  const [message, setMessage] = useRecoilState(messageAlertState);
  const [disabled, setDisabled] = React.useState(false);

  const id = useRecoilValue(selectedTicketState);
  function next1() {
    setDisabled(true);
    buyTicket(id, count, tokenState).then((res) => {
      if (res.length === 0 || res[0].error.length === 0) {
        navigate('/ticket/purchase/complete');
      } else {
        setOpen(true);
        setMessage(res[0].error);
        setDisabled(false);
      }
    });
  }

  const ticketsList = useRecoilValue(filteredTicketState);
  const count = useRecoilValue(countState);
  const tokenState = useRecoilValue(jwtTokenState);
  return (
    <>
      <Header url="/ticket/purchase/number_selection" />
      <AlertMessage open={open} message={message} handleClose={() => setOpen(false)} />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>内容確認</h2>
        {ticketsList.map((ticket) => (
          <div key={ticket.id}>
            <TicketConfirmation
              // mailaddress="sample@example.com"
              ticketName={ticket.title}
              usePeriod={ticket.valid_days}
              price={ticket.price}
              count={count}
              totalPrice={ticket.price * count}
            />
          </div>
        ))}
        <Button label="お支払い" onClick={next1} disabled={disabled} />
        <UrlLink
          title="特定商取引に基づく表示"
          url="/tokushou"
          back="/ticket/purchase/confirmation"
        />
      </Box>
    </>
  );
}

export default PurchaseTicketConfirmation;
