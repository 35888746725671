import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import { TicketSammaryStyle } from '../css/stylesheet';
import Button from './button';
import Box from '@mui/material/Box';
import ExplainArea from './explain_area';

interface Props {
  title: string;
  count: number;
  usePeriod: string;
  buyDate: string;
  onClick?: () => void;
}

function TicketOwnSammary({ ...props }: Props) {
  let button = null;
  if (props.onClick != null) {
    button = <Button label="チケットを表示する" onClick={props.onClick} />;
  }

  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={TicketSammaryStyle}>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title={props.title} text="" />
              </Box>
            </Box>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title={`購入日：${props.buyDate}`} text="" />
              </Box>
            </Box>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title={`使用期限：${props.usePeriod}`} text="" />
              </Box>
            </Box>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>購入枚数：{props.count.toString()}名分</Box>
            </Box>
            {button}
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default TicketOwnSammary;
