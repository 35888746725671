const Config = () => {
  if (process.env.NODE_ENV === 'test') {
    // test
    return {
      restful: {
        apiURL: process.env.REACT_APP_TEST_API_URL,
        stripeKey: process.env.REACT_APP_TEST_STRIPE_PUBLIC_KEY,
      },
    };
  } else if (process.env.NODE_ENV === 'production') {
    // production
    return {
      restful: {
        apiURL: process.env.REACT_APP_PROD_API_URL,
        stripeKey: process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY,
      },
    };
  }
  // development
  return {
    restful: {
      apiURL: process.env.REACT_APP_DEV_API_URL,
      stripeKey: process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY,
    },
  };
};
export const restfulConfig = Config().restful;
