import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import ButtonTicket from '../../uiParts/button_ticket';
import {
  getPurchaseTicketState,
  selectedTicketState,
} from '../../../states/rootStates/purchaseTickets';
import { countState } from '../../../states/rootStates/count';
import { useRecoilValue, useSetRecoilState } from 'recoil';

function PurchaseTicketList() {
  let navigate = useNavigate();
  const setCount = useSetRecoilState(countState);
  const setSelectedTicket = useSetRecoilState(selectedTicketState);
  function resetCount() {
    setCount(() => 0);
  }
  function setTicket(id: string) {
    setSelectedTicket(() => id);
  }

  function next1(value: string) {
    setTicket(value);
    resetCount();
    navigate('/ticket/purchase/sammary');
  }

  const ticketsList = useRecoilValue(getPurchaseTicketState);
  return (
    <>
      <Header url="/" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>チケット一覧</h2>
        {ticketsList.length === 0 && <h3>該当のチケットがありません</h3>}
        {ticketsList.map((ticket) => (
          <ButtonTicket
            key={ticket.id}
            label={ticket.title}
            url={ticket.icon_url}
            onClick={() => next1(ticket.id)}
          />
        ))}
      </Box>
    </>
  );
}

export default PurchaseTicketList;
