import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import TicketDetail from '../../uiParts/ticket_detail';
import Button from '../../uiParts/button';
import UrlLink from '../../uiParts/url_link';
import { filteredTicketState } from '../../../states/rootStates/purchaseTickets';
import { useRecoilValue } from 'recoil';

function PurchaseTicketDetail() {
  let navigate = useNavigate();
  function next1() {
    navigate('/ticket/purchase/number_selection');
  }
  const ticketsList = useRecoilValue(filteredTicketState);
  return (
    <>
      <Header url="/ticket/purchase/sammary" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>チケットの情報</h2>
        {ticketsList.map((ticket) => (
          <div key={ticket.id}>
            <TicketDetail
              title={ticket.title}
              price={ticket.price}
              description={ticket.description}
              useLimit={ticket.valid_days}
              usePeriod={ticket.usage_period}
              useArea={ticket.area}
              ticket_type={ticket.ticket_type}
              how_to_use={ticket.how_to_use}
            />
          </div>
        ))}
        <Button label="次へ" onClick={next1} />
        <UrlLink title="特定商取引に基づく表示" url="/tokushou" back="/ticket/purchase/detail" />
      </Box>
    </>
  );
}

export default PurchaseTicketDetail;
