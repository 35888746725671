import React from 'react';
import * as styles from '../css/stylesheet';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

interface Props {
  title: string;
  useLimit: string;
  usePeriod: string;
  count: number;
  valid: string;
}

function TicketOwnContents({ title, useLimit, usePeriod, count, valid }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          {title}
          <hr />

          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div style={styles.TicketDetailLabel}>使用期限</div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ textAlign: 'right', float: 'right', paddingTop: '1em' }}>
                {useLimit}
              </div>
            </Grid>

            <Grid item xs={4}>
              <div style={styles.TicketDetailLabel}>利用期間</div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ textAlign: 'right', float: 'right', paddingTop: '1em' }}>
                {usePeriod}
              </div>
            </Grid>
            {valid && (
              <>
                <Grid item xs={4}>
                  <div style={styles.TicketDetailLabel}>利用期限</div>
                </Grid>
                <Grid item xs={8}>
                  <div style={{ textAlign: 'right', float: 'right', paddingTop: '1em' }}>
                    {valid}
                  </div>
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <div style={styles.TicketDetailLabel}>人数</div>
            </Grid>
            <Grid item xs={8}>
              <div style={{ textAlign: 'right', float: 'right', paddingTop: '1em' }}>
                {count}名分
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default TicketOwnContents;
