import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TicketDetailStyle, TicketDetailHeaderStyle } from '../css/stylesheet';
import Box from '@mui/material/Box';

interface Props {
  title: string;
  cardName: string;
  cardNumberLast4: string;
  expYear: string;
  expMonth: string;
}

function zeroPadding(num: string) {
  return num.padStart(2, '0');
}

function CardDetail({ title, cardName, cardNumberLast4, expYear, expMonth }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={TicketDetailStyle}>
            <CardContent style={TicketDetailHeaderStyle}>{title}</CardContent>

            <div style={{ margin: '10px 15px' }}>
              <label>カード名：</label>
              <label style={{ marginLeft: '30px' }}>{cardName}</label>
            </div>
            <div style={{ margin: '5px 15px' }}>
              <label>カード番号：</label>
              <label style={{ marginLeft: '13px' }}>**** **** **** {cardNumberLast4}</label>
            </div>
            <div style={{ margin: '5px 15px' }}>
              <label>有効期限：</label>
              <label style={{ marginLeft: '30px' }}>
                {zeroPadding(expMonth)}/{zeroPadding(expYear.substring(2))}
              </label>
            </div>

            <br />
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default CardDetail;
