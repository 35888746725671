import { createTheme } from '@mui/material/styles';

const color = '#E0A86E';

// カラーテーマ
export const themeMain = createTheme({
  palette: {
    primary: {
      main: color,
    },
  },
});

export const TicketImageStyle = {
  backgroundColor: color,
  borderRadius: 20,
  width: '100%',
  marginTop: '1em',
  border: '4px solid',
  borderColor: color,
};

export const TicketSammaryStyle = {
  borderRadius: 20,
  width: '100%',
  marginTop: '1em',
};

export const TicketDetailStyle = {
  borderRadius: 20,
  width: '100%',
  marginTop: '1em',
};

export const TicketDetailHeaderStyle = {
  backgroundColor: color,
};

// Box系
export const FlexGrow = { flexGrow: 1 };

export const FlexP1Bgcolor = { display: 'flex', p: 1, bgcolor: 'background.paper' };

//// ページのスタイル
export const FlexP1BgcolorPage = {
  display: 'flex',
  p: 1,
  bgcolor: 'background.paper',
  flexDirection: 'column' as 'column',
  justifyContent: 'center' as 'center',
};

// スピナー
export const Spinner = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

// モーダル
export const Modal = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 2,
  // borderRadius: '2em',
};

// アラート
export const Alert = {
  position: 'absolute' as 'absolute',
  top: '15%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  // bgcolor: 'background.paper',
  p: 2,
};

// チケット内容のタイトル
export const TicketDetailLabel = {
  border: `2px solid ${color}`,
  paddingTop: '1em',
  paddingBottom: '1em',
  borderRadius: '10px',
  width: '100%',
  display: 'inline-block',
  textAlign: 'center' as 'center',
};

export const HeaderColor = { backgroundColor: '#FFF' };

export const Scroll = {
  margin: 'auto',
  width: '90%',
  // fontSize: '140%',
  lineHeight: '1.5em',
  textAlign: 'left' as 'left',
  overflow: 'hidden',
  // color: 'red',
};

export const ScrollSpan = {
  display: 'inline-block',
  paddingLeft: '100%',
  whiteSpace: 'nowrap' as 'nowrap',
  lineHeight: '1em',
  animation: 'scrollAnime 5s linear infinite',
};
