import React from 'react';

interface Props {
  title: string;
  text: string;
}

function ExplainArea({ title, text }: Props) {
  const breakedText = text.split('\n').map((line, key) => <div key={key}>{line}</div>);

  return (
    <>
      {title}
      <hr />
      {breakedText}
    </>
  );
}

export default ExplainArea;
