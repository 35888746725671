import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TicketDetailStyle, TicketDetailHeaderStyle } from '../css/stylesheet';
import ExplainArea from './explain_area';
import TicketContents from './ticket_contents';
import Box from '@mui/material/Box';

interface Props {
  title: string;
  price: number;
  useLimit: number;
  usePeriod: number;
  description: string;
  useArea: string;
  ticket_type: string;
  how_to_use: string;
}

function TicketDetail({ title, price, useLimit, usePeriod, description, useArea, ticket_type, how_to_use }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={TicketDetailStyle}>
            <CardContent style={TicketDetailHeaderStyle}>{title}</CardContent>
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <TicketContents
                  title="チケット内容"
                  useLimit={`${useLimit}日間（購入日含む）`}
                  usePeriod={ticket_type === "1" ? `有効にした当日中` : `有効にしてから${usePeriod}時間`}
                  price={price}
                />
              </Box>
            </Box>
            <br />
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title="チケット詳細" text={description} />
              </Box>
            </Box>
            <br />
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title="利用可能なエリア" text={useArea} />
              </Box>
            </Box>
            <br />
            <Box sx={styles.FlexP1Bgcolor}>
              <Box sx={styles.FlexGrow}>
                <ExplainArea title="チケットの利用方法" text={how_to_use} />
              </Box>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default TicketDetail;
