import React from 'react';
import Header from '../../uiParts/header';
import { useRecoilValue } from 'recoil';
import { pathState } from '../../../states/rootStates/path';

function Tokushou() {
  const url = useRecoilValue(pathState);
  return (
    <>
      <Header url={url} />
      <h1>特定商取引法に基づく表記</h1>
      <br />
      <br />
      <h2>販売会社</h2>
      北鉄加賀バス株式会社
      <h3>所在地</h3>
      石川県小松市鶴ヶ島町8番地1
      <h3>代表責任者</h3>
      新谷　良二
      <h3>連絡先</h3>
      0761-77-3080
      <br />
      <br />
      <h2>販売価格</h2>
      各商品又はサービス等の購入ページにて表示する価格 商品代金以外に必要な費用
      /送料、消費税等
      商品・サービス等の購入、ダウンロード等に必要となるインターネット接続料金、通信料金等
      <h2>支払方法</h2>
      <h3>クレジットカード決済</h3>
      ご利用頂けるカード:Visa・MasterCard・American Express・JCB・Diners Club・Discover
      <h2>支払い時期</h2>
      各カード会社の引き落とし日
      <h2>商品引渡し・サービス提供の時期</h2>
      商品・サービス等購入の決済手続完了後直ちに。
      <br />
      <h2>動作環境</h2>
      <h3>iOS</h3>
      iOS 14以上
      <br />
      対応ブラウザ：Safari
      <br />
      <h3>Android</h3>
      Android 9以上
      <br />
      対応ブラウザ：Google Chrome
      <br />
      <h2>返品・キャンセル等</h2>
      商品の使用開始期限前で、未使用の場合に限ります。
    </>
  );
}

export default Tokushou;
