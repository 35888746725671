import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import { TicketSammaryStyle } from '../css/stylesheet';
import Price from './price';
import Button from './button';
import Box from '@mui/material/Box';

interface Props {
  title: string;
  price: number;
  onClick?: () => void;
}

function TicketSammary({ title, price, onClick }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={TicketSammaryStyle}>
            <CardContent>{title}</CardContent>

            <Grid container>
              <Grid item xs={8}>
                <Price price={price} />
              </Grid>
              <Grid
                item
                xs={4}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                <Button
                  size="small"
                  label="購入する"
                  onClick={onClick}
                  marginTop="0"
                  fontWeight="normal"
                  fontSize="1em"
                />
              </Grid>
            </Grid>
          </Card>
        </Box>
      </Box>
    </>
  );
}

export default TicketSammary;
