import { Card, CardContent, CardMedia } from "@mui/material";

interface DialogCardProps {
  image: string;
}

const DialogCard = (props: DialogCardProps) => {
  const { image } = props;
  return (
    <>
      <Card variant="outlined">
        <CardContent>
          <CardMedia 
            component={"img"}
            image={image}
          />
        </CardContent>
      </Card>
    </>
  )
}


export default DialogCard; 