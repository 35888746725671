import { useNavigate } from 'react-router-dom';
import * as styles from '../../css/stylesheet';
import Box from '@mui/material/Box';
import Button from '../../uiParts/button';

function PurchaseTicketComplete() {
  let navigate = useNavigate();
  function next1() {
    navigate('/');
  }
  return (
    <>
      <div style={{ marginTop: '10em' }} />
      <Box sx={styles.FlexP1BgcolorPage}>
        チケットの利用が可能になりました。
        <br />
        購入済みチケット表示画面でご確認ください。
        <Button label="OK" onClick={next1} />
      </Box>
    </>
  );
}

export default PurchaseTicketComplete;
