import * as React from 'react';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import TicketImage from '../../uiParts/ticket_image';
import TicketOwnContents from '../../uiParts/ticket_own_contents';
import Button from '../../uiParts/button';
import {
  filteredOwnTicketState,
  getOwnTicketActiveState,
  getOwnTicketStatusState,
  ownTicketsState,
} from '../../../states/rootStates/ownTickets';
import { jwtTokenState } from '../../../states/rootStates/jwtToken';
import { activateTicket, cancelTicket, takeTicket } from '../../../api/api';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import AlertMessage from '../../uiParts/alert_message';
import { openAlertState, messageAlertState } from '../../../states/rootStates/alert';

import Modal from '../../uiParts/modal';

function OwnershipTicketDetail() {
  const setOwnTicketData = useSetRecoilState(ownTicketsState);
  const [openUse, setOpenUse] = React.useState(false);
  const [openCancel, setOpenCancel] = React.useState(false);
  const [openGetOn, setOpenGetOn] = React.useState(false);
  const [openGetOff, setOpenGetOff] = React.useState(false);
  const [openTicket, setOpenTicket] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const handleOpenUse = () => setOpenUse(true);
  const handleCloseUse = () => setOpenUse(false);
  const handleOpenCancel = () => setOpenCancel(true);
  const handleCloseCancel = () => setOpenCancel(false);
  const handleOpenGetOn = () => setOpenGetOn(true);
  const handleCloseGetOn = () => setOpenGetOn(false);
  const handleOpenGetOff = () => setOpenGetOff(true);
  const handleCloseGetOff = () => setOpenGetOff(false);
  const handleOpenTicket = () => setOpenTicket(true);
  const handleCloseTicket = () => setOpenTicket(false);
  const [open, setOpen] = useRecoilState(openAlertState);
  const [message, setMessage] = useRecoilState(messageAlertState);

  let navigate = useNavigate();

  function next1() {
    handleOpenUse();
  }
  function next2() {
    handleOpenCancel();
  }
  function handleCancelTicket(value: string) {
    handleCloseCancel();
    cancelTicket(value, 9, tokenState, 0, 0).then((res) => {
      if (res.length === 0 || res[0].error.length === 0) {
        setOwnTicketData(res);
        navigate('/ticket/ownership/cancel');
      } else {
        setOpen(true);
        setMessage(res[0].error);
      }
    });
  }

  function handleUseTicket(value: string) {
    handleCloseUse();
    activateTicket(value, tokenState).then((res) => {
      if (res.length === 0 || res[0].error.length === 0) {
        setOwnTicketData(res);
      } else {
        setOpen(true);
        setMessage(res[0].error);
      }
    });
  }

  function handleGetOff() {
    handleCloseGetOff();
    handleOpenTicket();
  }

  function handleTakeOnTicket(value: string) {
    setDisabled(true);
    handleCloseUse();
    let latitude: number = 0;
    let longitude: number = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          latitude = position.coords.latitude; // 緯度
          longitude = position.coords.longitude; // 経度
          takeTicket(value, 1, tokenState, latitude, longitude).then((res) => {
            if (res.length === 0 || res[0].error.length === 0) {
              setOwnTicketData(res);
              handleOpenGetOn();
              setDisabled(false);
            } else {
              setOpen(true);
              setMessage(res[0].error);
              setDisabled(false);
            }
          });
        },
        // 位置情報取得失敗時
        function (error) {
          takeTicket(value, 1, tokenState, latitude, longitude).then((res) => {
            if (res.length === 0 || res[0].error.length === 0) {
              setOwnTicketData(res);
              handleOpenGetOn();
              setDisabled(false);
            } else {
              setOpen(true);
              setMessage(res[0].error);
              setDisabled(false);
            }
          });
        }
      );
    } else {
      takeTicket(value, 1, tokenState, latitude, longitude).then((res) => {
        if (res.length === 0 || res[0].error.length === 0) {
          setOwnTicketData(res);
          handleOpenGetOn();
          setDisabled(false);
        } else {
          setOpen(true);
          setMessage(res[0].error);
          setDisabled(false);
        }
      });
    }
  }

  function handleTakeOffTicket(value: string) {
    setDisabled(true);
    handleCloseUse();
    let latitude: number = 0;
    let longitude: number = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          latitude = position.coords.latitude; // 緯度
          longitude = position.coords.longitude; // 経度
          takeTicket(value, 0, tokenState, latitude, longitude).then((res) => {
            if (res.length === 0 || res[0].error.length === 0) {
              setOwnTicketData(res);
              handleOpenGetOff();
              setDisabled(false);
            } else {
              setOpen(true);
              setMessage(res[0].error);
              setDisabled(false);
            }
          });
        },
        // 位置情報取得失敗時
        function (error) {
          takeTicket(value, 0, tokenState, latitude, longitude).then((res) => {
            if (res.length === 0 || res[0].error.length === 0) {
              setOwnTicketData(res);
              handleOpenGetOff();
              setDisabled(false);
            } else {
              setOpen(true);
              setMessage(res[0].error);
              setDisabled(false);
            }
          });
        }
      );
    } else {
      takeTicket(value, 0, tokenState, latitude, longitude).then((res) => {
        if (res.length === 0 || res[0].error.length === 0) {
          setOwnTicketData(res);
          handleOpenGetOff();
          setDisabled(false);
        } else {
          setOpen(true);
          setMessage(res[0].error);
          setDisabled(false);
        }
      });
    }
  }

  const ticketsList = useRecoilValue(filteredOwnTicketState);
  const isActive = useRecoilValue(getOwnTicketActiveState).length === 0 ? 'block' : 'none';
  const isUseActive = useRecoilValue(getOwnTicketActiveState).length !== 0 ? 'block' : 'none';
  const isStatusOn = useRecoilValue(getOwnTicketStatusState).length === 0 ? 'block' : 'none';
  const isStatusOff = useRecoilValue(getOwnTicketStatusState).length !== 0 ? 'block' : 'none';
  const tokenState = useRecoilValue(jwtTokenState);

  return (
    <>
      <Header url="/ticket/ownership/list" />
      <AlertMessage open={open} message={message} handleClose={() => setOpen(false)} />
      <Box sx={styles.FlexP1BgcolorPage}>
        {ticketsList.map((ticket) => (
          <div key={ticket.id}>
            <TicketImage
              image_url={ticket.ticket.url}
              title={ticket.ticket.title}
              // display={isUseActive}
            />
            <TicketOwnContents
              title="チケット内容"
              useLimit={ticket.available}
              usePeriod={
                ticket.ticket.ticket_type === '1'
                  ? `有効にした当日中`
                  : `${ticket.ticket.usage_period / 24}日(${ticket.ticket.usage_period}時間)`
              }
              count={ticket.count}
              valid={ticket.valid}
            />
            <Button label="チケットを使う" onClick={() => next1()} display={isActive} />
            <Button label="チケットを払い戻す" onClick={() => next2()} display={isActive} />

            <div style={{ display: isUseActive }}>
              <div style={{ display: isStatusOn }}>
                <div style={{ textAlign: 'center' }}>降車する直前にこのボタンを押してください</div>
                <Button
                  label="降車する"
                  onClick={() => handleTakeOffTicket(ticket.id)}
                  disabled={disabled}
                />
                <div style={{ textAlign: 'center' }}>＞乗車中＜</div>
              </div>
              <div style={{ display: isStatusOff }}>
                <div style={{ textAlign: 'center' }}>乗車する直前にこのボタンを押してください</div>
                <Button
                  label="乗車する"
                  onClick={() => handleTakeOnTicket(ticket.id)}
                  disabled={disabled}
                />
              </div>
            </div>

            <Modal
              open={openUse}
              title="チケットの使用を開始しますか？"
              text={`チケットの有効期限は${ticket.available}までとなります。使用開始後の変更はできません。`}
              labelOK="使用開始"
              labelCancel="キャンセル"
              handleClose={handleCloseUse}
              handleNext={() => handleUseTicket(ticket.id)}
            />
            <Modal
              open={openCancel}
              title="チケットの払い戻し申請をしますか？"
              text={`一度払い戻されたチケットは取り消しできません。必要な場合はチケット一覧から再購入してください。`}
              labelOK="払い戻す"
              labelCancel="キャンセル"
              handleClose={handleCloseCancel}
              handleNext={() => handleCancelTicket(ticket.id)}
            />
            <Modal
              open={openGetOn}
              title="乗車を確認しました"
              text={`降車時には降車ボタンを押してください`}
              labelOK="OK"
              labelCancel="キャンセル"
              // handleClose={handleCloseGetOn}
              handleNext={handleCloseGetOn}
            />
            <Modal
              open={openGetOff}
              title="降車を確認しました"
              text={`OKボタンを押してチケット確認画面を乗務員へ提示してください`}
              labelOK="OK"
              labelCancel="キャンセル"
              // handleClose={handleCloseGetOff}
              handleNext={handleGetOff}
            />
            <Modal
              open={openTicket}
              title="ご乗車ありがとうございました"
              text={`乗務員へご提示ください`}
              labelOK="確認しました"
              labelCancel="キャンセル"
              // handleClose={handleCloseTicket}
              handleNext={handleCloseTicket}
              url={ticket.ticket.url}
              ticketName={ticket.ticket.title}
              count={ticket.count}
              scroll={true}
            />
          </div>
        ))}
      </Box>
    </>
  );
}

export default OwnershipTicketDetail;
