import React from 'react';
import * as styles from '../css/stylesheet';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';

interface Props {
  image_url: string;
  title: string;
  display?: string;
}

function TicketImage({ image_url, title, display = 'none' }: Props) {
  return (
    <>
      <Box sx={styles.FlexP1Bgcolor}>
        <Box sx={styles.FlexGrow}>
          <Card style={styles.TicketImageStyle}>
            <CardMedia component="img" height="100%" image={`${image_url}`}></CardMedia>
            <CardContent>{title}</CardContent>
          </Card>
        </Box>
      </Box>
      <div style={{ display: display }}>
        <div style={styles.Scroll}>
          <span style={styles.ScrollSpan}>チケット使用中</span>
        </div>
      </div>
    </>
  );
}

export default TicketImage;
