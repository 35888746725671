import { atom, selector } from 'recoil';
import { OwnticketModel } from '../../models/ownTicket';

export const ownTicketsState = atom<OwnticketModel[]>({
  key: 'OwnTickets',
  default: Promise.resolve([]),
});

export const filteredOwnTicketBeforeUseState = selector({
  key: 'filteredOwnTicketBeforeUse',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));

    const now = new Date();
    return list.then((v) =>
      v.filter(
        (item) =>
          item.valid_compare === null &&
          new Date(Date.parse(item.available_compare)) > now &&
          !(item.status === 9 || item.status === 99)
      )
    );
  },
});

export const filteredOwnTicketUseState = selector({
  key: 'filteredOwnTicketUse',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));

    const now = new Date();
    return list.then((v) =>
      v.filter(
        (item) =>
          new Date(Date.parse(item.valid_compare)) >= now &&
          !(item.status === 9 || item.status === 99)
      )
    );
  },
});

export const filteredOwnTicketAfterUseState = selector({
  key: 'filteredOwnTicketAfterUse',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));

    const now = new Date();
    return list.then((v) =>
      v.filter(
        (item) =>
          (new Date(Date.parse(item.valid_compare)) < now ||
            new Date(Date.parse(item.available_compare)) < now) &&
          !(item.status === 9 || item.status === 99)
      )
    );
  },
});

export const selectedOwnTicketState = atom({
  key: 'selectedOwnTicket',
  default: '',
});

export const filteredOwnTicketState = selector({
  key: 'filteredOwnTicket',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));
    let filterId = get(selectedOwnTicketState);

    return list.then((v) => v.filter((item) => item.id === filterId));
  },
});

export const getOwnTicketActiveState = selector({
  key: 'getOwnTicketActive',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));
    let filterId = get(selectedOwnTicketState);
    return list.then((v) =>
      v.filter((item) => item.id === filterId && item.valid_compare !== null)
    );
  },
});

export const getOwnTicketStatusState = selector({
  key: 'getOwnTicketStatus',
  get: ({ get }) => {
    const list = Promise.resolve(get(ownTicketsState));
    let filterId = get(selectedOwnTicketState);
    return list.then((v) => v.filter((item) => item.id === filterId && item.status === 0));
  },
});
