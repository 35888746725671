import React from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { themeMain } from '../css/stylesheet';

interface Props {
  size?: 'small' | 'medium' | 'large';
  label: string;
  borderRadius?: number;
  width?: string;
  marginTop?: string;
  fontWeight?: 'normal' | 'bold' | 'lighter' | 'bolder';
  fontSize?: string;
  disabled?: boolean;
  display?: string;
  onClick?: () => void;
}

function NextButton({
  size = 'medium',
  label,
  borderRadius = 20,
  width = '100%',
  marginTop = '1em',
  display = 'block',
  fontWeight = 'bold',
  fontSize = '20px',
  ...props
}: Props) {
  return (
    <>
      <ThemeProvider theme={themeMain}>
        <Button
          variant="contained"
          size={size}
          style={{
            borderRadius: borderRadius,
            width: width,
            marginTop: marginTop,
            display: display,
            fontWeight: fontWeight,
            fontSize: fontSize,
          }}
          {...props}
        >
          {label}
        </Button>
      </ThemeProvider>
    </>
  );
}

export default NextButton;
