import axios from 'axios';
import { purchaseTicketModel } from '../models/purchaseTicket';
import { OwnticketModel } from '../models/ownTicket';
import { OwnCardModel } from '../models/ownCard';
import { restfulConfig } from '../config';
import { OwnCardDetailModel } from '../models/owndCardDetail';

function getHeaders(token: string) {
  return {
    Authorization: token,
    'content-type': 'application/json; charset=utf-8',
  };
}

const apiUrl = restfulConfig.apiURL;

function getDefaultPurchase() {
  return {
    id: '',
    type: '',
    description: '',
    price: 0,
    tax: 0,
    title: '',
    url: '',
    valid_days: 0,
    usage_period: 0,
    area: '',
    ticket_type: '0',
    how_to_use: '',
    icon_url: '',
    error: '',
  };
}

function getDefaultOwn() {
  return {
    id: '',
    ticket_id: '',
    user_id: '',
    created: '',
    updated: '',
    valid: '',
    valid_compare: '',
    available: '',
    available_compare: '',
    count: 0,
    status: 0,
    ticket: {
      id: '',
      type: '',
      description: '',
      price: 0,
      tax: 0,
      title: '',
      url: '',
      valid_days: 0,
      usage_period: 0,
      area: '',
      ticket_type: '0',
      how_to_use: '',
      icon_url: '',
      error: '',
    },
    error: '',
  };
}

function getDefaultOwnCard() {
  return {
    user_id: '',
    stripe_id: '',
    error: '',
  };
}

function getDefaultOwnCardDetail() {
  return {
    id: '',
    name: '',
    last4: '',
    exp_month: '',
    exp_year: '',
    error: '',
  };
}

// 購入チケット取得
export async function getPurchaseTickets() {
  let list: purchaseTicketModel[] = [];
  try {
    await axios(`${apiUrl}/purchasables`)
      .then((res) => {
        list = res.data.map((item: purchaseTicketModel) => {
          return {
            id: item.id,
            type: item.type,
            description: item.description,
            price: item.price,
            tax: item.tax,
            title: item.title,
            url: item.url,
            valid_days: item.valid_days,
            usage_period: item.usage_period,
            area: item.area,
            ticket_type: item.ticket_type,
            how_to_use: item.how_to_use,
            icon_url: item.icon_url,
            error: '',
          };
        });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: purchaseTicketModel = getDefaultPurchase();
          tmp.error = error.message;
          list = [tmp];
        } else {
          throw error;
        }
      });
  } catch (error) {
    throw error;
  }
  return list;
}

function changeDate(strDate: string) {
  const date = new Date(Date.parse(strDate));
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
}
function changeDatetime(strDate: string) {
  if (strDate === null) {
    return null;
  }
  const date = new Date(Date.parse(strDate));
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${date.getHours()}:${(
    '00' + date.getMinutes()
  ).slice(-2)}`;
}

// 所有チケット取得
export async function getOwnTickets(token: string) {
  let list: OwnticketModel[] = [];
  try {
    await axios(`${apiUrl}/own`, {
      headers: getHeaders(token),
    })
      .then((res) => {
        list = res.data.map((item: OwnticketModel) => {
          return {
            id: item.id,
            ticket_id: item.ticket_id,
            user_id: item.user_id,
            created: changeDate(item.created),
            updated: changeDate(item.updated),
            valid: changeDatetime(item.valid),
            valid_compare: item.valid,
            available: changeDatetime(item.available),
            available_compare: item.available,
            count: item.count,
            status: item.status,
            ticket: {
              id: item.ticket.id,
              type: item.ticket.type,
              description: item.ticket.description,
              price: item.ticket.price,
              tax: item.ticket.tax,
              title: item.ticket.title,
              url: item.ticket.url,
              valid_days: item.ticket.valid_days,
              usage_period: item.ticket.usage_period,
              ticket_type: item.ticket.ticket_type,
              how_to_use: item.ticket.how_to_use,
              icon_url: item.ticket.icon_url,
              area: item.ticket.area,
            },
            error: '',
          };
        });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnticketModel = getDefaultOwn();
          tmp.error = error.message;
          list = [tmp];
        } else {
          throw error;
        }
      });
  } catch (error) {
    throw error;
  }
  return list;
}

// チケット購入
export async function buyTicket(id: string, count: number, token: string) {
  const params = { ticket_id: id, count: count };
  let list: OwnticketModel[] = [];
  try {
    // const response = await axios.post(`${apiUrl}/own`, params, { headers: getHeaders(token) });
    await axios.post(`${apiUrl}/own`, params, { headers: getHeaders(token) }).catch((error) => {
      if (axios.isAxiosError(error)) {
        let tmp: OwnticketModel = getDefaultOwn();
        tmp.error = error?.response?.data?.detail;
        list = [tmp];
      }
    });
  } catch (error) {
    throw error;
  }
  return list;
}

// チケットアクティベート
export async function activateTicket(id: string, token: string) {
  let list: OwnticketModel[] = [];
  try {
    await axios.put(`${apiUrl}/own/${id}`, null, { headers: getHeaders(token) }).catch((error) => {
      if (axios.isAxiosError(error)) {
        let tmp: OwnticketModel = getDefaultOwn();
        tmp.error = error.message;
        list = [tmp];
      }
    });
    if (list.length === 0) {
      await axios(`${apiUrl}/own`, {
        headers: getHeaders(token),
      })
        .then((res) => {
          list = res.data.map((item: OwnticketModel) => {
            return {
              id: item.id,
              ticket_id: item.ticket_id,
              user_id: item.user_id,
              created: changeDate(item.created),
              updated: changeDate(item.updated),
              valid: changeDatetime(item.valid),
              valid_compare: item.valid,
              available: changeDatetime(item.available),
              available_compare: item.available,
              count: item.count,
              status: item.status,
              ticket: {
                id: item.ticket.id,
                type: item.ticket.type,
                description: item.ticket.description,
                price: item.ticket.price,
                tax: item.ticket.tax,
                title: item.ticket.title,
                url: item.ticket.url,
                valid_days: item.ticket.valid_days,
                usage_period: item.ticket.usage_period,
                ticket_type: item.ticket.ticket_type,
                how_to_use: item.ticket.how_to_use,
                icon_url: item.ticket.icon_url,
                area: item.ticket.area,
              },
              error: '',
            };
          });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            let tmp: OwnticketModel = getDefaultOwn();
            tmp.error = error.message;
            list = [tmp];
          } else {
            throw error;
          }
        });
    }
  } catch (error) {
    throw error;
  }
  return list;
}

// チケットキャンセル
export async function cancelTicket(
  id: string,
  status: number,
  token: string,
  latitude: number,
  longitude: number
) {
  const params = { ticket_id: id, status: status, latitude: latitude, longitude: longitude };
  let list: OwnticketModel[] = [];
  try {
    await axios
      .post(`${apiUrl}/own/change`, params, {
        headers: getHeaders(token),
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnticketModel = getDefaultOwn();
          tmp.error = error.message;
          list = [tmp];
        }
      });
    if (list.length === 0) {
      await axios(`${apiUrl}/own`, {
        headers: getHeaders(token),
      })
        .then((res) => {
          list = res.data.map((item: OwnticketModel) => {
            return {
              id: item.id,
              ticket_id: item.ticket_id,
              user_id: item.user_id,
              created: changeDate(item.created),
              updated: changeDate(item.updated),
              valid: changeDatetime(item.valid),
              valid_compare: item.valid,
              available: changeDatetime(item.available),
              available_compare: item.available,
              count: item.count,
              status: item.status,
              ticket: {
                id: item.ticket.id,
                type: item.ticket.type,
                description: item.ticket.description,
                price: item.ticket.price,
                tax: item.ticket.tax,
                title: item.ticket.title,
                url: item.ticket.url,
                valid_days: item.ticket.valid_days,
                usage_period: item.ticket.usage_period,
                ticket_type: item.ticket.ticket_type,
                how_to_use: item.ticket.how_to_use,
                icon_url: item.ticket.icon_url,
                area: item.ticket.area,
              },
              error: '',
            };
          });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            let tmp: OwnticketModel = getDefaultOwn();
            tmp.error = error.message;
            list = [tmp];
          } else {
            throw error;
          }
        });
    }
  } catch (error) {
    throw error;
  }
  return list;
}

// チケットもぎり
export async function takeTicket(
  id: string,
  status: number,
  token: string,
  latitude: number,
  longitude: number
) {
  const params = { ticket_id: id, status: status, latitude: latitude, longitude: longitude };
  let list: OwnticketModel[] = [];
  try {
    await axios
      .post(`${apiUrl}/own/change`, params, {
        headers: getHeaders(token),
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnticketModel = getDefaultOwn();
          tmp.error = error.message;
          list = [tmp];
        }
      });
    if (list.length === 0) {
      await axios(`${apiUrl}/own`, {
        headers: getHeaders(token),
      })
        .then((res) => {
          list = res.data.map((item: OwnticketModel) => {
            return {
              id: item.id,
              ticket_id: item.ticket_id,
              user_id: item.user_id,
              created: changeDate(item.created),
              updated: changeDate(item.updated),
              valid: changeDatetime(item.valid),
              valid_compare: item.valid,
              available: changeDatetime(item.available),
              available_compare: item.available,
              count: item.count,
              status: item.status,
              ticket: {
                id: item.ticket.id,
                type: item.ticket.type,
                description: item.ticket.description,
                price: item.ticket.price,
                tax: item.ticket.tax,
                title: item.ticket.title,
                url: item.ticket.url,
                valid_days: item.ticket.valid_days,
                usage_period: item.ticket.usage_period,
                ticket_type: item.ticket.ticket_type,
                how_to_use: item.ticket.how_to_use,
                icon_url: item.ticket.icon_url,
                area: item.ticket.area,
              },
              error: '',
            };
          });
        })
        .catch((error) => {
          if (axios.isAxiosError(error)) {
            let tmp: OwnticketModel = getDefaultOwn();
            tmp.error = error.message;
            list = [tmp];
          } else {
            throw error;
          }
        });
    }
  } catch (error) {
    throw error;
  }
  return list;
}

// カード登録
export async function registCard(
  cardToken: string,
  cardName: string = '',
  phoneNumber: string = '',
  token: string
) {
  const params = { card_token: cardToken, card_name: cardName, phone_number: phoneNumber };
  let list: OwnCardModel[] = [];
  try {
    await axios
      .post(`${apiUrl}/own/card`, params, {
        headers: getHeaders(token),
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnCardModel = getDefaultOwnCard();
          tmp.error = error.message;
          list = [tmp];
        }
      });
  } catch (error) {
    throw error;
  }
  return list;
}

// カード取得
export async function getCard(token: string) {
  //let list: OwnCardDetailModel[] = [];
  let carddata;
  try {
    await axios(`${apiUrl}/own/card/${token}`, {
      headers: getHeaders(token),
    })
      .then((res) => {
        carddata = res;
        // list = res.data.map((item: OwnCardDetailModel) => {
        //   return {
        //     id: item.id,
        //     name: item.name,
        //     last4: item.last4,
        //     exp_month: item.exp_month,
        //     exp_year: item.exp_year,
        //     error: '',
        //   };
        // });
      })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnCardDetailModel = getDefaultOwnCardDetail();
          tmp.error = error.message;
          //list = [tmp];
        }
      });
  } catch (error) {
    throw error;
  }
  return carddata;
}

// カード削除
export async function deleteCard(token: string) {
  let list: OwnCardModel[] = [];
  try {
    await axios
      .delete(`${apiUrl}/own/card/${token}`, { headers: getHeaders(token) })
      .catch((error) => {
        if (axios.isAxiosError(error)) {
          let tmp: OwnCardModel = getDefaultOwnCard();
          tmp.error = error.message;
          list = [tmp];
        }
      });
  } catch (error) {
    throw error;
  }
  return list;
}
