import { atom } from 'recoil';

export const openAlertState = atom<boolean>({
  key: 'openAlert',
  default: false,
});

export const messageAlertState = atom<string>({
  key: 'messageAlert',
  default: '',
});
