import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import Menu from './components/pages/menu/menu';
import CreditCardView from './components/pages/credit_card/credit_card_view';
import CreditCardRegistrarion from './components/pages/credit_card/credit_card_registrarion';
import PurchaseTicketList from './components/pages/ticket/purchase_ticket_list';
import PurchaseTicketSammary from './components/pages/ticket/purchase_ticket_sammary';
import PurchaseTicketDetail from './components/pages/ticket/purchase_ticket_detail';
import PurchaseTicketNumberSelection from './components/pages/ticket/purchase_ticket_number_selection';
import PurchaseTicketNumberConfirmation from './components/pages/ticket/purchase_ticket_confirmation';
import PurchaseTicketComplete from './components/pages/ticket/purchase_ticket_complete';
import OwnershipTicketList from './components/pages/ticket/ownership_ticket_list';
import OwnershipTicketDetail from './components/pages/ticket/ownership_ticket_detail';
import OwnershipTicketCancel from './components/pages/ticket/ownership_ticket_cancel';
import Tokushou from './components/pages/tokushou/tokushou';
import LoadingSpinner from './components/uiParts/loading_spinner';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { restfulConfig } from './config';

const stripeKey = restfulConfig.stripeKey === undefined ? '' : restfulConfig.stripeKey;
const stripePromise = loadStripe(stripeKey);

function App() {
  return (
    <RecoilRoot>
      <Suspense fallback={<LoadingSpinner />}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Menu />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/credit_card/view" element={<CreditCardView />} />
            <Route
              path="/credit_card/registrarion"
              element={
                <Elements stripe={stripePromise}>
                  <CreditCardRegistrarion />
                </Elements>
              }
            />
            <Route path="/ticket/purchase/list" element={<PurchaseTicketList />} />
            <Route path="/ticket/purchase/sammary" element={<PurchaseTicketSammary />} />
            <Route path="/ticket/purchase/detail" element={<PurchaseTicketDetail />} />
            <Route
              path="/ticket/purchase/number_selection"
              element={<PurchaseTicketNumberSelection />}
            />
            <Route
              path="/ticket/purchase/confirmation"
              element={<PurchaseTicketNumberConfirmation />}
            />
            <Route path="/ticket/purchase/complete" element={<PurchaseTicketComplete />} />
            <Route path="/ticket/ownership/list" element={<OwnershipTicketList />} />
            <Route path="/ticket/ownership/detail" element={<OwnershipTicketDetail />} />
            <Route path="/ticket/ownership/cancel" element={<OwnershipTicketCancel />} />
            <Route path="/tokushou" element={<Tokushou />} />
          </Routes>
        </BrowserRouter>
      </Suspense>
    </RecoilRoot>
  );
}

export default App;
