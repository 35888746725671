import React from 'react';

interface Props {
  onClick?: () => void;
}

function Minus({ ...props }: Props) {
  return (
    <>
      <img src="/images/svg/minus.svg" alt="minus" {...props} />
    </>
  );
}

export default Minus;
