import React from 'react';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { themeMain } from '../css/stylesheet';

interface Props {
  size?: 'small' | 'medium' | 'large';
  label: string;
  borderRadius?: number;
  width?: string;
  marginTop?: string;
  disabled?: boolean;
  display?: string;
  onClick?: () => void;
}

function ButtonDelete({
  size = 'medium',
  label,
  borderRadius = 20,
  width = '100%',
  marginTop = '1em',
  display = 'block',
  ...props
}: Props) {
  return (
    <>
      <ThemeProvider theme={themeMain}>
        <Button
          variant="contained"
          size={size}
          style={{
            borderRadius: borderRadius,
            width: width,
            marginTop: marginTop,
            display: display,
          }}
          {...props}
        >
          {label}
        </Button>
      </ThemeProvider>
    </>
  );
}

export default ButtonDelete;
