import React from 'react';
import Grid from '@mui/material/Grid';
import Price from './price';
import NumberSelection from './number_selection';

interface Props {
  price: number;
  count: number;
  functions: any;
}

function TicketNumber({ price, count, functions }: Props) {
  return (
    <>
      購入枚数
      <hr />
      <Grid container>
        <Grid item xs={7}>
          <Price price={price} />
        </Grid>
        <Grid item xs={5}>
          <NumberSelection
            count={count}
            functions={{ plus: functions.Minus, minus: functions.Plus }}
          />
        </Grid>
      </Grid>
      <hr />
      ・一度に使用する分をご購入ください。
    </>
  );
}

export default TicketNumber;
