// import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { jwtTokenState } from '../../../states/rootStates/jwtToken';
import { useRecoilValue, useRecoilState } from 'recoil';
import { registCard } from '../../../api/api';
import { openAlertState, messageAlertState } from '../../../states/rootStates/alert';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';

import './card.css';

import AlertMessage from '../../uiParts/alert_message';

function CreditCardRegistrarion() {
  let navigate = useNavigate();

  const tokenState = useRecoilValue(jwtTokenState);
  const [open, setOpen] = useRecoilState(openAlertState);
  const [message, setMessage] = useRecoilState(messageAlertState);

  const stripe = useStripe();
  const elements = useElements();

  //div要素のStripeElement部品への置き換えは、レンダリング後に実施
  useEffect(() => {
    try {
      if (stripe != null && elements != null) {
        const cardNumberElement = elements.create('cardNumber');
        const cardExpiryElement = elements.create('cardExpiry');
        const cardCvcElement = elements.create('cardCvc');

        cardNumberElement.mount('#card-number-input');
        cardExpiryElement.mount('#card-expiry-input');
        cardCvcElement.mount('#card-cvc-input');
      }
    } catch (e) {
      alert(e);
    }
  }, [stripe, elements]);

  const validateWithoutCard = (card_name: string, phone_number: string) => {
    const regexp = /^0[-0-9]{9,12}$/;
    let message = '';

    if (!card_name?.trim()) {
      message = '名前は必須入力です';
    } else if (!phone_number?.trim()) {
      message = '電話番号は必須入力です';
    } else if (!regexp.test(phone_number)) {
      message = '電話番号を正しい形式で入力してください';
    }
    if (message) {
      setOpen(true);
      setMessage(message);
      return false;
    }
    return true;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    const card_name = document.querySelector<HTMLInputElement>('#cardName')?.value || '';
    const phone_number = document.querySelector<HTMLInputElement>('#phoneNumber')?.value || '';

    if (validateWithoutCard(card_name, phone_number)) {
      if (stripe != null && elements != null) {
        const cardNumberElement = elements.getElement(CardNumberElement);

        if (cardNumberElement != null) {
          const { error, token } = await stripe.createToken(cardNumberElement, { name: card_name });

          if (error) {
            if (error.message) {
              setOpen(true);
              setMessage(error.message);
            }
          } else {
            if (token) {
              registCard(token.id, card_name, phone_number, tokenState).then((res) => {
                if (res.length === 0 || res[0].error.length === 0) {
                  setOpen(false);
                  navigate('/credit_card/view');
                } else {
                  setOpen(true);
                  setMessage('カード情報の登録に失敗しました。');
                }
              });
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Header url="/credit_card/view" />
      <AlertMessage open={open} message={message} handleClose={() => setOpen(false)} />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>クレジットカード登録</h2>

        <form className="credit_card_register" onSubmit={handleSubmit}>
          <div>
            <label>お名前</label>
            <div style={{ marginTop: '10px', marginBottom: '20px', padding: '0 14px' }}>
              <input
                type="text"
                id="cardName"
                name="cardName"
                placeholder="Name"
                style={{
                  width: '100%',
                  height: '16.7969px',
                  margin: '0 0',
                  position: 'relative',
                  left: '-14px',
                }}
              />
            </div>
          </div>
          <div>
            <label>電話番号</label>
            <div style={{ marginTop: '10px', marginBottom: '20px', padding: '0 14px' }}>
              <input
                type="text"
                id="phoneNumber"
                name="phoneNumber"
                placeholder="PhoneNumber"
                style={{
                  width: '100%',
                  height: '16.7969px',
                  margin: '0 0',
                  position: 'relative',
                  left: '-14px',
                }}
              />
            </div>
          </div>

          <div>
            <label>カード番号</label>
            <div id="card-number-input" />
          </div>
          <div>
            <label>有効期限</label>
            <div id="card-expiry-input" />
          </div>
          <div>
            <label>CVC</label>
            <div id="card-cvc-input" />
          </div>
          <button type="submit" style={{ width: '100%', borderRadius: '20px' }}>
            登録
          </button>
        </form>
      </Box>
    </>
  );
}

export default CreditCardRegistrarion;
