import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { jwtTokenState } from '../../../states/rootStates/jwtToken';
import * as styles from '../../css/stylesheet';
import Header from '../../uiParts/header';
import Box from '@mui/material/Box';
import Button from '../../uiParts/button';
import ButtonDelete from '../../uiParts/button_delete';
import CardDetail from '../../uiParts/card_detail';

import Modal from '../../uiParts/modal';
import { getCard, deleteCard } from '../../../api/api';
import { useRecoilValue, useRecoilState } from 'recoil';

import AlertMessage from '../../uiParts/alert_message';
import { openAlertState, messageAlertState } from '../../../states/rootStates/alert';

function CreditCardView() {
  let navigate = useNavigate();
  const tokenState = useRecoilValue(jwtTokenState);

  //削除モーダル判定
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteComp, setOpenDeleteComp] = useState(false);
  const handleCloseDelete = () => setOpenDelete(false);
  const handleCloseDeleteComp = () => setOpenDeleteComp(false);

  const [cardVisible, setCardVisible] = useState(false);
  const [nonCardVisible, setNonCardVisible] = useState(false);
  const [card, setCard] = useState({ id: '', name: '', last4: '', exp_month: '', exp_year: '' });
  const [open, setOpen] = useRecoilState(openAlertState);
  const [message, setMessage] = useRecoilState(messageAlertState);

  function next1() {
    navigate('/credit_card/registrarion');
  }

  //#region 初期表示制御（カード情報取得）
  useEffect(() => {
    if (tokenState) {
      getCard(tokenState).then((res) => {
        if (res != null) {
          if (res['data']['id'] != null && res['data']['id'] !== '') {
            setCard({
              id: res['data']['id'],
              name: res['data']['name'],
              last4: res['data']['last4'],
              exp_month: res['data']['exp_month'],
              exp_year: res['data']['exp_year'],
            });
            nonCardVisible && setNonCardVisible(false);
            cardVisible || setCardVisible(true);
          } else {
            nonCardVisible || setNonCardVisible(true);
            cardVisible && setCardVisible(false);
            if (res['data']['error'] !== '') {
              setOpen(true);
              setMessage('カード情報の取得に失敗しました。');
            }
          }
        }
      });
    }
  }, [cardVisible, nonCardVisible, setMessage, setOpen, tokenState, openDeleteComp]);

  //#endregion

  //#region カード削除関連
  const btnDelete = () => {
    setOpenDelete(true);
  };

  function handleDeleteCard() {
    openDelete && setOpenDelete(false);
    deleteCard(tokenState).then((res) => {
      if (res.length === 0 || res[0].error.length === 0) {
        openDeleteComp || setOpenDeleteComp(true);
      } else {
        setOpen(true);
        setMessage('カード情報の削除に失敗しました。');
      }
    });
  }
  //#endregion

  return (
    <>
      <Header url="/" />
      <Box sx={styles.FlexP1BgcolorPage}>
        <h2>クレジットカード情報</h2>
        <div style={{ marginTop: '.5em' }} />
        <AlertMessage open={open} message={message} handleClose={() => setOpen(false)} />
        登録されているクレジットカード
        <Box sx={styles.FlexGrow}>
          <div
            style={{
              textAlign: 'center',
              marginTop: '10px',
              backgroundColor: '#e1e1e1',
              height: '60px',
              display: nonCardVisible ? '' : 'none',
            }}
          >
            <label>クレジットカードが登録されていません</label>
          </div>

          <div style={{ display: cardVisible ? '' : 'none' }}>
            <CardDetail
              title="登録カード"
              cardName={card.name}
              cardNumberLast4={card.last4}
              expMonth={card.exp_month}
              expYear={card.exp_year}
            />
            <ButtonDelete label="削除" width="20%" onClick={btnDelete} />
          </div>
        </Box>
        <Box sx={styles.FlexP1BgcolorPage} style={{ display: nonCardVisible ? '' : 'none' }}>
          <Button label="新規登録" width="100%" onClick={next1} />
        </Box>
        <Modal
          open={openDelete}
          title="カード削除"
          text="登録済みクレジットカードを削除しますか？"
          labelOK="削除"
          labelCancel="キャンセル"
          handleClose={handleCloseDelete}
          handleNext={() => handleDeleteCard()}
        />
        <Modal
          open={openDeleteComp}
          title="カード削除"
          text={`カードを削除しました`}
          labelOK="OK"
          labelCancel="キャンセル"
          handleNext={handleCloseDeleteComp}
        />
      </Box>
    </>
  );
}

export default CreditCardView;
